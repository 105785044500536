<template xmlns:el-col="http://www.w3.org/1999/html">
  <div class="live">

    <el-row>
      <el-col :span="16">
        <video controls class="video" :src="video.url"></video>
      </el-col>
      <el-col :span="8">
        <div>
        </div>
      </el-col>
    </el-row>

  </div>
</template>

<script>
export default {
  name: "Live",
  components: {},
  data() {
    return {
      video: {
        url: "https://outin-584b80e56cdc11eb91fb00163e1c8dba.oss-cn-shanghai.aliyuncs.com/sv/2771c900-177ed20e955/2771c900-177ed20e955.mp4?Expires=1615967094&OSSAccessKeyId=LTAI8bKSZ6dKjf44&Signature=XVaDpNGt4Y4XOclsnzC1ZKYTfsU%3D"
      },
      user: [
        {
          id: "",
          avavtor: "",
          content: ""
        }
      ]
    }
  }
}
</script>

<style scoped>
.live {
  height: 100%;
  width: 100%;

}

.live .video {
  height: 25rem;
  width: 35rem;
}
</style>
